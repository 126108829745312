import { Action, Prize } from "../types"

interface Auth {
  isLoading: boolean
  token: string
  authenticated: boolean
  error: any
}
const initState: Auth = {
  isLoading: false,
  token: '',
  authenticated: false,
  error: null
}
// Reducer
export const reducer = (state: Auth=initState, action: Action) => {
  const newData = action.param as any
  switch (action.type) {
    case 'AUTHENTICATION':
      return {...state}
    case 'AUTHENTICATION_SUCCESS':
      return {
        isLoading: false,
        token: newData.token,
        authenticated: true,
        error: null
      }
    case 'AUTHENTICATION_FAILURE':
      return {
        isLoading: false,
        token: '',
        authenticated: true,
        error: newData.error
        }
    case 'RESET_AUTHENTICATION':
      return initState
    default:
      return state
  }
}