import { Action } from "../types"


const initState: string = 'id'

// Reducer
export const reducer = (state=initState, action: Action) => {
  switch (action.type) {
    case 'CHANGE_LANG':
      return action.param
    default:
      return state
  }
}