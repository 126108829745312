import fontsData from "./fontsData"
import imagesData from "../static-assets"
import baseScale from "./baseScale"
import generalApiType from './generalApiType'
import endPoint from "./endPoint"
const allConst = {
    fontsData,
    imagesData,
    baseScale,
    generalApiType,
    code:'LuckyEgg',
    endPoint
}

export default allConst