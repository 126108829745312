import { useState, useLayoutEffect } from 'react'
import allConst from '../const/'

export interface FontsLoadedState{
    loaded: boolean[]
    font_count: number
    completed: boolean
}

declare global {
    interface Document {
      fonts: any
    }
  }
  declare let FontFace: any
const { fontsData } = allConst

    /**
 *Preloader for font family.
*
* @description
* This is our preloader for font family to the document.
*
* @returns FontsLoadedState
*/
const useLoadFonts = (): FontsLoadedState => {

    const [ isLoaded, setLoaded ]  = useState<FontsLoadedState>(
        {
            loaded: [],
            font_count: fontsData.length,
            completed: false
        }
    )
    const handleLoad = () => {
        if(document.fonts) {
            fontsData.forEach(({name, src}: any)=>{
                const font = new FontFace(name,src)
                document.fonts.add(font)
                font.load()
                font.loaded.then((fontFace: any) => {
                    // isLoaded.loaded.push(true)
                    setLoaded((old)=>({...old, loaded: [...old.loaded, true]}))
                })
            })
        }
    }

    useLayoutEffect(()=>{
        if(isLoaded.loaded.length === isLoaded.font_count&&isLoaded.loaded.every((item)=>(item === true))){
            setLoaded({...isLoaded, completed: true})
        }
    }, [isLoaded.loaded.length])

    useLayoutEffect(()=>handleLoad(), [])

     return { ...isLoaded }
}
export default useLoadFonts