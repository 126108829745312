import { Action, Prize } from "../types"
import { isNumeric } from "../utils/isNumeric"
import { numberWithCommas } from "../utils/numberWithCommas"

const initState: Prize = {
  id:0,
  isLoading: false,
  manifest: null,
  isClaim: false,
  error: null
}


// Reducer
export const reducer = (state: Prize=initState, action: Action) => {
  const newPrize = action.param as any
  switch (action.type) {
    case 'SET_ID_PRIZE':
      return {
        id:newPrize.id_transaction,
        isLoading: false,
        manifest: newPrize.manifest,
        isClaim: false,
        error: null
      }
    case 'GET_PRIZE':
      return {
        id:0,
        isLoading: true,
        manifest: null,
        isClaim: false,
        error: null
      }
    case 'GET_PRIZE_SUCCESS':
      return {
        id:newPrize.id_transaction,
        isLoading: false,
        manifest: {...newPrize, name_prize: isNumeric(newPrize.name_prize)?numberWithCommas(newPrize.name_prize):newPrize.name_prize},
        isClaim: false,
        error: null
      }
    case 'GET_PRIZE_FAILURE':
      return {
        id:0,
        isLoading: false,
        manifest: null,
        isClaim: false,
        error: newPrize.error
      }
    case 'RESET_PRIZE':
      return initState
    case 'CLAIMED_PRIZE':
      return state
    case 'CLAIMED_PRIZE_SUCCESS':
      return {
        ...state,
        isClaim: true
      }
    default:
      return state
  }
}