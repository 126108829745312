import { Scale } from "../types"

const initState: Scale = [0,0]

// Reducer
export const reducer = (state: Scale=initState, action: any) => {
  switch (action.type) {
    case 'UPDATE_SCALE':
      return [...action.param]
    default:
      return state
  }
}