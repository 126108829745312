import { reducer as dimenReducer } from './dimen'
import { reducer as userReducer } from './user'
import { reducer as scaleReducer } from './scale'
import { reducer as langReducer } from './lang'
import { reducer as textureReducer } from './texture'
import { reducer as prizeReducer } from './prize'
import { reducer as overlayReducer } from './overlay'
import { reducer as toastReducer } from './toast'
import { reducer as msgboxReducer } from './msgbox'
import { reducer as infoReducer } from './info'
import { reducer as historyReducer } from './history'
import { reducer as lastwinnerReducer } from './lastwinner'
import { reducer as codeReducer } from './code'
import { reducer as gameReducer } from './game'
import { reducer as authReducer } from './auth'
import { combineReducers, Reducer as R,  CombinedState } from "redux"


export const Reducer: R<CombinedState<any>> =combineReducers({
  _dimen: dimenReducer,
  _userData: userReducer,
  _scale: scaleReducer,
  _language: langReducer,
  _texture: textureReducer,
  _prize: prizeReducer,
  _overlay: overlayReducer,
  _toast: toastReducer,
  _msgbox: msgboxReducer,
  _info: infoReducer,
  _history: historyReducer,
  _lastwinner: lastwinnerReducer,
  _code: codeReducer,
  _game: gameReducer,
  _auth: authReducer
})

export default Reducer