import { useState, useLayoutEffect } from 'react'
import allConst from './../const/'
export interface ImagesLoadedState{
    loaded: boolean[]
    images_count: number
    completed: boolean
}
const { imagesData } = allConst

    /**
 *Preloader for Images.
*
* @description
* This is our preloader for Images and with this, images wilbe in cache.
*
* @returns ImagesLoadedState
*/
const useLoadImages = (): ImagesLoadedState => {
    const [ isLoaded, setLoaded ]  = useState<ImagesLoadedState>(
        {
            loaded: [],
            images_count: imagesData.length,
            completed: false
        }
    )
    const handleLoad = () => {
        if(document.fonts) {
            imagesData.forEach((image: string) => { 
                const img: any = new Image()
                img.onload = ()=>{
                    // isLoaded.loaded.push(true)
                    setLoaded((old)=>({...old, loaded: [...old.loaded, true]}))
                }
                img.src = image 
            })
        }
    }

    useLayoutEffect(()=>{
        if(isLoaded.loaded.length === isLoaded.images_count && isLoaded.loaded.every((item)=>(item === true))){
            setLoaded((i)=>({...i, completed: true}))
        }
    }, [isLoaded.loaded.length])

    useLayoutEffect(()=>handleLoad(), [])

     return { ...isLoaded }
}
export default useLoadImages