import { combineEpics, ofType } from "redux-observable";
import {
  flatMap,
  mergeMap,
  subscribeOn,
  delay,
  map,
  catchError,
} from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { queueScheduler, Observable } from "rxjs";
import allConst from "../const";
import __ from "../hook/useLang";
import languages from "../lang";

const lang: any = languages;
const urlParams = new URLSearchParams(window.location.search);
//urlParams.get('key')!==null?urlParams.get('key')!.split('token#').join(''):btoa('')
const randomString = generateRandomString(100);

try {
  console.log(document.URL);
  window.localStorage.setItem(
    "token",
    document.URL.split("&text=")[0].split("?")[1].split("key=token#")[
      document.URL.split("&text=")[0].split("?")[1].split("key=token#").length -
        1
    ]
  );
  window.localStorage.setItem(
    "game_name",
    document.URL.split("&text=")[document.URL.split("&text=").length - 1]
  );
  modifyUrl(
    atob(window.localStorage.getItem("game_name")!),
    window.location.origin.concat("/".concat(randomString))
  );
} catch (error) {
  // window.alert('Not found 404');
}

function modifyUrl(title: string, url: string) {
  if (typeof window.history.replaceState != "undefined") {
    var obj = {
      Title: title,
      Url: url,
    };

    // @ts-ignore
    window.history.pushState(null, null, url);
  }
}

function generateRandomString(length: number) {
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";

  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

export const token = atob(window.localStorage.getItem("token")! || btoa(""));
// const app_token = urlParams.get('key')!==null?'?app_key='+urlParams.get('APP_KEY'):'';
const toastTimeout = 5000;

const getPrize = (action$: any, store: any) => {
  return action$.pipe(
    ofType("GET_PRIZE"),
    flatMap((action: any) =>
      ajax({
        url: action.param,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
      }).pipe(
        map((payload) => payload.response),
        // delay(3000),
        mergeMap((payload: any) => {
          return Observable.create((observer: any) => {
            if (!payload && (!payload["data"] || !payload["message"]))
              observer.next({
                type: "SET_TOAST",
                param: {
                  message: lang[store.value._language].error_connetion,
                  type: "error",
                  timeOut: toastTimeout,
                },
              });
            if (!payload["data"] && payload["message"]) {
              if (
                token &&
                payload["message"] ===
                  lang[store.value._language].error_message["Unauthorized"]
              ) {
                observer.next({
                  type: "SET_TOAST",
                  param: {
                    message:
                      lang[store.value._language].error_message["Expired"],
                    type: "error",
                    timeOut: toastTimeout,
                  },
                });
                setTimeout(() => {
                  window.location.href =
                    window.location.origin + window.location.pathname;
                }, toastTimeout);
                return;
              }
              observer.next({
                type: "SET_TOAST",
                param: {
                  message:
                    lang[store.value._language].error_message[
                      payload["message"]
                    ] || payload["message"],
                  type: "error",
                  timeOut: toastTimeout,
                },
              });
            }
            if (payload && payload["data"]) {
              // setTimeout(()=>{
              observer.next({
                type: "GET_PRIZE_SUCCESS",
                param: { ...payload["data"] },
              });
              // }, 3000)

              setTimeout(() => {
                observer.next({ type: "CHANGE_OVERLAY", param: "prize" });
              }, 3500);
            } else {
              observer.next({
                type: "GET_PRIZE_FAILURE",
                param: { error: payload["message"] },
              });
            }
          });
        }),
        catchError((error: any) =>
          Observable.create((observer: any) => {
            if (error.response && error.response.message) {
              observer.next({
                type: "SET_TOAST",
                param: {
                  message: error.response.message,
                  type: "error",
                  timeOut: toastTimeout,
                },
              });
              if (error.response.message == "Token Expired") {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("game_name");
              }
            }
            return observer.next({
              type: "SET_TOAST",
              param: {
                message:
                  lang[store.value._language].error_message[error.message] ||
                  error.message,
                type: "error",
                timeOut: toastTimeout,
              },
            });
          })
        )
      )
    )
  );
};

const claimPrize = (action$: any, store: any) => {
  return action$.pipe(
    ofType("CLAIMED_PRIZE"),
    flatMap((action: any) =>
      ajax
        .post(
          action.param.url,
          {
            ...action.param.data,
            ...action.param.data.address,

            grant_type: "claim_prize",
            rt_rw: action.param.data.address["rt/rw"],
            kelurahan_desa: action.param.data.address["kelurahan/desa"],
            kota_kecamatan: action.param.data.address["kota/kecamatan"],
          },
          {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          }
        )
        .pipe(
          map((payload) => payload.response),
          delay(300),
          mergeMap((payload: any) => {
            if (!payload && !payload["data"])
              return Observable.create((observer: any) => {
                return observer.next({
                  type: "SET_TOAST",
                  param: {
                    message: lang[store.value._language].error_connetion,
                    type: "error",
                    timeOut: toastTimeout,
                  },
                });
              });

            if (payload["message"])
              return Observable.create((observer: any) => {
                if (payload["message"] === "Transaction Expired!") {
                  observer.next({
                    type: "SET_TOAST",
                    param: {
                      message: "Transaction Expired!",
                      type: "error",
                      timeOut: toastTimeout,
                    },
                  });
                }
                const toast_category: any = {
                  merch: () =>
                    observer.next({
                      type: "SET_TOAST",
                      param: {
                        message:
                          lang[store.value._language].success_claim_merch ||
                          payload["message"],
                        type: "success",
                        timeOut: toastTimeout,
                      },
                    }),
                  chips: () =>
                    observer.next({
                      type: "SET_TOAST",
                      param: {
                        message:
                          lang[store.value._language].success_claim_chips ||
                          payload["message"],
                        type: "success",
                        timeOut: toastTimeout,
                        style: { wordWrapWidth: 650 },
                      },
                    }),
                };
                Object.keys(toast_category).indexOf(
                  store.value._prize.manifest.category
                ) > -1 &&
                  toast_category[store.value._prize.manifest.category]();
                observer.next({ type: "CLAIMED_PRIZE_SUCCESS" });
                // observer.next({type: 'GET_HISTORY', param: allConst.endPoint(store.value._game.data.id, '5').history})
                // observer.next({type: 'GET_LASTWINNER', param: allConst.endPoint(store.value_game.data.id, '6').lastwinner, background: true})
              });
          }),
          catchError((error: any) =>
            Observable.create((observer: any) => {
              if (error.response && error.response.message) {
                observer.next({
                  type: "SET_TOAST",
                  param: {
                    message: error.response.message,
                    type: "error",
                    timeOut: toastTimeout,
                  },
                });
                if (error.response.message == "Token Expired") {
                  window.localStorage.removeItem("token");
                  window.localStorage.removeItem("game_name");
                }
              }
              return observer.next({
                type: "SET_TOAST",
                param: {
                  message:
                    lang[store.value._language].error_message[error.message] ||
                    error.message,
                  type: "error",
                  timeOut: toastTimeout,
                },
              });
            })
          )
        )
    )
  );
};

const auth = (action$: any, store: any) => {
  return action$.pipe(
    ofType("AUTHENTICATION"),
    flatMap((action: any) =>
      ajax
        .getJSON(
          action.param,
          token
            ? { Authorization: "Bearer " + token, Accept: "application/json" }
            : {}
        )
        .pipe(
          mergeMap((payload: any) => {
            if (!payload && !payload["data"])
              return Observable.create((observer: any) => {
                return observer.next({
                  type: "SET_TOAST",
                  param: {
                    message: lang[store.value._language].error_connetion,
                    type: "error",
                    timeOut: toastTimeout,
                  },
                });
              });
            if (payload["message"])
              Observable.create((observer: any) => {
                observer.next({
                  type: "SET_TOAST",
                  param: {
                    message:
                      lang[store.value._language].error_message[
                        payload["message"]
                      ] || payload["message"],
                    type: "info",
                    timeOut: toastTimeout,
                  },
                });
              });

            if (payload && payload["data"] && !payload["data"].profile)
              return Observable.create((observer: any) => {
                return observer.next({
                  type: "AUTHENTICATION_FAILURE",
                  param: {
                    message: lang[store.value._language].no_record,
                    type: "info",
                    timeOut: toastTimeout,
                  },
                });
              });
            if (payload && payload["data"] && payload["data"].profile) {
              return Observable.create((observer: any) => {
                if (payload["data"].profile.username) {
                  observer.next({
                    type: `AUTHENTICATION_SUCCESS`,
                    param: { token },
                  });
                  const unCrackEgg = payload[
                    "data"
                  ].profile.transaction_list.filter(
                    (trx: any) => !trx.result_from_egg.img_url
                  );
                  // console.log()
                  observer.next({
                    type: `UPDATE_USER`,
                    param: {
                      nickname: payload["data"].profile.username,
                      point: payload["data"].profile.total_point,
                      purchasedEgg: unCrackEgg.length
                        ? (unCrackEgg[0].name_egg as string).toLowerCase()
                        : "",
                      profile: payload["data"].profile,
                    },
                  });
                }
              });
            }
          }),
          catchError((error: any) =>
            Observable.create((observer: any) => {
              if (error.response && error.response.message) {
                observer.next({
                  type: "SET_TOAST",
                  param: {
                    message: error.response.message,
                    type: "error",
                    timeOut: toastTimeout,
                  },
                });
                if (error.response.message == "Token Expired") {
                  window.localStorage.removeItem("token");
                  window.localStorage.removeItem("game_name");
                }
              }

              return observer.next({
                type: "SET_TOAST",
                param: {
                  message:
                    lang[store.value._language].error_message[error.message] ||
                    error.message,
                  type: "error",
                  timeOut: toastTimeout,
                },
              });
            })
          )
        )
    )
  );
};

const mustUpdateUserData = (action$: any, store: any) => {
  return action$.pipe(
    ofType(
      "CLAIMED_PRIZE_SUCCESS",
      "CLAIMED_PRIZE_FAILURE",
      "PURCHASE_EGG_SUCCESS",
      "PURCHASE_EGG_FAILURE",
      "CLEAR_OVERLAY"
    ),
    // delay(1000),
    mergeMap((action: any) =>
      Observable.create((observer: any) => {
        return observer.next({
          type: "AUTHENTICATION",
          param: allConst.endPoint().auth,
        });
      })
    )
  );
};

interface Types {
  [index: string]: string;
}
const generalAPICall = (action$: any, store: any) => {
  // let temper: any
  const types: Types = allConst.generalApiType as any;

  return action$.pipe(
    ofType(...Object.keys(types).map((key: string) => types[key])),
    flatMap((action: any) => {
      return ajax({
        url: action.param,
        method: action.body ? "POST" : "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
        ...(action.body ? { body: action.body } : {}),
      }).pipe(
        map((payload) => {
          console.log("first payload", payload);
          // if(payload.response.success==false)
          // // Observable.create((observer: any)=> {

          // //     observer.next({type: 'SET_TOAST', param: { message: payload.response.message, type: 'error', timeOut: toastTimeout}})
          // // })

          return payload.response;
        }),
        delay(100),
        mergeMap((payload: any) => {
          if (payload && payload.success && payload["data"]) {
            return Observable.create((observer: any) => {
              // observer.next({type: 'SET_TOAST', param: { message: lang[store.value._language].error_message[payload["message"]]||payload["message"], type: 'info', timeOut: toastTimeout}})
              observer.next({
                type: `${action.type}_SUCCESS`,
                param: payload["data"],
              });
            });
          }
          console.log(payload, payload["message"]);
          if (
            payload &&
            !payload.success &&
            payload["message"] &&
            !action.background
          )
            return Observable.create((observer: any) => {
              if (
                token &&
                payload["message"] ===
                  lang[store.value._language].error_message["Unauthorized"]
              ) {
                observer.next({
                  type: "SET_TOAST",
                  param: {
                    message:
                      lang[store.value._language].error_message["Expired"],
                    type: "error",
                    timeOut: toastTimeout,
                  },
                });
                setTimeout(() => {
                  window.location.href =
                    window.location.origin + window.location.pathname;
                }, toastTimeout);
                return;
              }
              console.log(payload["message"]);
              observer.next({
                type: "SET_TOAST",
                param: {
                  message:
                    lang[store.value._language].error_message[
                      payload["message"]
                    ] || payload["message"],
                  type: "info",
                  timeOut: toastTimeout,
                },
              });
            });

          if (!payload)
            return Observable.create((observer: any) => {
              return observer.next({
                type: "SET_TOAST",
                param: {
                  message: lang[store.value._language].error_connetion,
                  type: "error",
                  timeOut: toastTimeout,
                },
              });
            });
          if (payload && !payload["data"] && !payload["message"])
            return Observable.create((observer: any) => {
              return observer.next({
                type: "SET_TOAST",
                param: {
                  message: lang[store.value._language].error_connetion,
                  type: "error",
                  timeOut: toastTimeout,
                },
              });
            });
          if (
            payload &&
            payload["data"] &&
            !Object.values(payload["data"]).length
          )
            return Observable.create((observer: any) => {
              return observer.next({
                type: "SET_TOAST",
                param: {
                  message: lang[store.value._language].no_record,
                  type: "error",
                  timeOut: toastTimeout,
                },
              });
            });
          return Observable.create((observer: any) => {
            observer.next({ type: `TYPELESS` });
          });
        }),
        catchError((error: any) =>
          Observable.create((observer: any) => {
            if (error.response && error.response.message) {
              observer.next({
                type: "SET_TOAST",
                param: {
                  message: error.response.message,
                  type: "error",
                  timeOut: toastTimeout,
                },
              });
              if (error.response.message == "Token Expired") {
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("game_name");
              }
            }
            return observer.next({
              type: "SET_TOAST",
              param: {
                message:
                  lang[store.value._language].error_message[error.message] ||
                  error.message,
                type: "error",
                timeOut: toastTimeout,
              },
            });
          })
        )
      );
    })
  );
};

export const epic = (action$: any, store: any) =>
  combineEpics(
    getPrize,
    claimPrize,
    generalAPICall,
    auth,
    mustUpdateUserData
  )(action$.pipe(subscribeOn(queueScheduler)), store);
