
const path = {
    api: '/api/v2',
    games: '/game'
}

const backendLocalhost = {
    host: '192.168.2.243',
    // host: '127.0.0.1',
    protocol: 'http://',
    port: '8080',
}

const backendStaging = {
    host: 'api-luckyeggv2.krakenteamdev.com',
}

const backendProdDev = {
    host: 'api-luckyeggv2.luckyegg.xyz',
}

const backendProd = {
    host: 'api.luckyegg.xyz',
}
let hostPort;

switch (window.location.host) {
    case 'game.lucky-egg.mengudara.online':
        hostPort = backendStaging.host
        break;
    case 'luckyegg.luckyegg.xyz':
        hostPort = backendProdDev.host
        break;
    case 'luckyegg.xyz':
        hostPort = backendProd.host
        break;
    default:
        hostPort = backendLocalhost.host + ':' + backendLocalhost.port
        // hostPort = backendStaging.host
        // hostPort = backendProd.host
        break;
}


const base_url = `${window.location.protocol}//` + hostPort
const base_api = base_url + path.api
export const base_game = base_api + path.games
// const urlParams = new URLSearchParams(window.location.search);
// const myParam = urlParams.get('APP_KEY')!==null?'?app_key='+urlParams.get('APP_KEY'):'';

interface EndPoint {
    userdata: string
    prize: string
    lastwinner: string
    history: string
    info: string
    purchase: string
    claim: string
    game: string
    auth: string
    sk: string
}

export const GAME_NAME = window.location.host == 'luckyegg.xyz' ? 'luckyegg' : 'luckyegg-dev';

/**
*Ganerate .
*
* @description
* This is our EndPoint.
* @param {id_game?}
* @param {param?}
* @param {id_game} are not required just for auth and game
* @returns EndPoint
*/

const endPoint: (id_game?: string, param?: string) => EndPoint = (id_game = '', param = '') => ({
    auth: base_game + `/${id_game || GAME_NAME}` + '/profile',
    userdata: '',
    prize: base_game + `/${id_game || GAME_NAME}/break-the-egg?egg_id=${param}`,
    lastwinner: base_game + `/${id_game || GAME_NAME}/last-winner`,
    history: base_game + `/${id_game || GAME_NAME}/history/${param}`,
    info: base_game + `/${id_game || GAME_NAME}/list-egg`,
    purchase: base_game + `/${id_game || GAME_NAME}/purchase-item`,
    claim: base_game + `/${id_game || GAME_NAME}/claimed-prize`,
    game: base_game,
    sk: base_game + `/${id_game || GAME_NAME}/sk`
})

// ajax({
//     url: action.param+app_token,
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': 'Bearer '+store.value._auth.token
//     }})

export default endPoint