interface GeneralApicallConst {
    history: string
    lastWinner: string
    info: string
    game: string,
    user: string,
    purchase: string
}

const generalApiType: GeneralApicallConst = {
    history: 'GET_HISTORY',
    lastWinner: 'GET_LASTWINNER',
    info: 'GET_INFO',
    game: 'GET_GAMEID',
    user: 'GET_USERDATA',
    purchase: 'PURCHASE_EGG'
}

export default generalApiType
