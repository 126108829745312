const fontsData = [
    {
        name: 'Aller',
        src: 'url("/assets/fonts/aller/Aller.ttf"), url("/assets/fonts/aller/Aller.woff") format("woff")'
    },
    {
        name: 'BadaBoomBB',
        src: `url('/assets/fonts/badaboom-bb/badaboom-bb.regular.ttf'), url('/assets/fonts/badaboom-bb/badaboom-bb.regular-webfont.woff2'), url('/assets/fonts/badaboom-bb/badaboom-bb.regular-webfont.woff')`
    },
    {
        name: 'DKLongreach',
        src: `url("/assets/fonts/longreach/Longreach.otf")`
    },
    {
        name: 'Exo',
        src: `url("/assets/fonts/exo/exo2-regular.otf"), url("/assets/fonts/exo/exo2-regular.woff2"), url("/assets/fonts/exo/exo2-regular.woff")`
    },
    {
        name: 'OpenSans',
        src: `url('/assets/fonts/open-sans/opensans-regular.ttf') format('truetype'), url('/assets/fonts/open-sans/opensans-regular-webfont.woff2') format('woff2'), url('/assets/fonts/open-sans/opensans-regular-webfont.woff') format('woff')`
    },
    {
        name: 'GothamRoundedBook',
        src: `url('/assets/fonts/gothamrnd-book/gothamrnd-book.woff')  format('woff')`
    }
]
export default fontsData