import React from 'react'
import {  MsgBox, Action } from "../types"
import { Container, Text, Sprite } from '@inlet/react-pixi'


const initState: MsgBox = {
  isAvaliable: true,
  cancellation: true,
  title: 'empty',
  content: (<></>)
}


// Reducer
export const reducer = (state: MsgBox=initState, action: Action) => {
  let newMsgbox = action.param as MsgBox
  switch (action.type) {
    case 'SET_MSGBOX':
      return {
        ...newMsgbox,
        isAvaliable: false,
      }
    case 'UNSET_MSGBOX':
      return {
          isAvaliable: true,
          title: '',
          content: (<></>),
          cancellation: false,
        }
    default:
      return state
  }
}