import { Action, GeneralAPICall } from "../types"

const initState: GeneralAPICall = {
  isLoading: false,
  data: null,
  error: null
}

export const genApiCall = (type: string, state: GeneralAPICall=initState,  action: Action)=>{
  const newData = action.param as GeneralAPICall
  switch (action.type) {
    case type:
      return {
        isLoading: true,
        data: null,
        error: null
      }
    case type+'_SUCCESS':
      return {
        isLoading: false,
        data: {...newData},
        error: null
      }
    case type+'_FAILURE':
      return {
          isLoading: false,
          data: null,
          error: newData.error
        }
    default:
      return state
  }
}