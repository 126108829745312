import { Dimen, Action} from "../types"

// Dont use zero or negative for initial Dimension
const initState: Dimen = {
  width: 10,
  height: 10
}


// Reducer
export const reducer = (state: Dimen=initState, action: Action) => {
  switch (action.type) {
    case 'UPDATE_DIMEN':
      return {
        ...state,
        ...action.param as Dimen
      }
    default:
      return state
  }
}