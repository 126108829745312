import id from './id'
import { Language } from '../hook/useLang'
interface Languages {
    id: Language
    [idx: string]: Language
}

    /**
 *All language.
*
* @description
* This is all of the language avaliable.
*/
const languages : Languages = {
    id
}

export default languages 