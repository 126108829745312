import { Action, User } from "../types"


const initState = {
    nickname: "defaultNickname",
    point: 0,
    purchasedEgg: ""
}

// Reducer
export const reducer = (state=initState, action: Action) => {
  const newUser = action.param as Partial<User>
  switch (action.type) {
    case 'UPDATE_USER':
      return {
        ...state,
        ...newUser
      }
    default:
      return state
  }
}