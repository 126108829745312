import { Action } from "../types"

interface Overlay { active: string, prev:string}
const initState:  Overlay= {
  active: "",
  prev:''
}


// Reducer
export const reducer = (state: Overlay=initState, action: Action) => {
  switch (action.type) {
    case 'CHANGE_OVERLAY':
      return {
        active: action.param,
        prev: state.active
      }
    case 'CLEAR_OVERLAY':
    return {
        active: '',
        prev: state.active
      }
    default:
      return state
  }
}