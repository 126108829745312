import bg_all from './bg_all.png'
import bg_dark from './bg_dark.png'
import btn_close from './btn_close.png'
import btn_next from './btn_next.png'
import btn_prev from './btn_prev.png'
import egg_breaker_islan from './egg_breaker_island.png'
import egg_container from './egg_container.png'
import empty_egg from './empty_egg.png'
import history_container from './history_container.png'
import info_container from './info_container.png'
import last_winner_container from './last_winner_container.png'
import left_pannel_container from './left-panel_container.png'
import msgbox_container from './msgbox_container.png'
import paging_container from './paging_container.png'
import point_container from './point_container.png'
import star_icon from './star_icon.png'
import toast_danger from './toast_danger.png'
import toast_error from './toast_error.png'
import toast_info from './toast_info.png'
import toast_success from './toast_success.png'

import btn_send_form_claim from './claim-form/button_send.png'
import blink_cursor_tip from './egg-breaker/blink_cursor_tip.png'
import bronze_egg_breaker from './egg-breaker/bronze.png'
import silver_egg_breaker from './egg-breaker/silver.png'
import gold_egg_breaker from './egg-breaker/gold.png'
import platinum_egg_breaker from './egg-breaker/platinum.png'
import diamond_egg_breaker from './egg-breaker/diamond.png'
import glowing_effect_egg_breaker from './egg-breaker/glowing_effect.png'
import btn_hammer_egg_breaker from './egg-breaker/btn_hammer.png'

import bronze_crack_egg_breaker from './egg-breaker/bronze_crack.png'
import silver_crack_egg_breaker from './egg-breaker/silver_crack.png'
import gold_crack_egg_breaker from './egg-breaker/gold_crack.png'
import platinum_crack_egg_breaker from './egg-breaker/platinum_crack.png'
import diamond_crack_egg_breaker from './egg-breaker/diamond_crack.png'

import hammer from './egg-breaker/hammer.png'
import swing_effect from './egg-breaker/swing_effect.png'
import hammered_effect from './egg-breaker/hammered_effect.png'
import hammer_shadow_effect from './egg-breaker/hammer_shadow_effect.png'
import shadow_effect from './egg-breaker/shadow_effect.png'

import star from './last-winner/star.png'
import trophy_icon from './last-winner/trophy.png'

import icon_exit from './left-panel/keluar_lg.png'
import icon_history from './left-panel/history_lg.png'
import icon_info from './left-panel/info_lg.png'

import bronze_egg from './mid-panel/bronze.png'
import silver_egg from './mid-panel/silver.png'
import gold_egg from './mid-panel/gold.png'
import platinum_egg from './mid-panel/platinum.png'
import diamon_egg from './mid-panel/diamon.png'
import btn_trade from './mid-panel/btn_trade.png'

import btn_negative from './msg-box/btn_negative.png'
import btn_positive from './msg-box/btn_positive.png'

import tray from './prize/tray.png'
import glowing_effect from './prize/glowing_effect.png'
import lighting_filter from './prize/lighting_filter.png'
import bg_title_prize from './prize/bg_title_prize.png'
import tray_shadow from './prize/tray_shadow.png'
import btn_claim from './prize/btn_claim.png'
import btn_back from './prize/btn_back.png'

const imagesData: Array<string> = [
    bg_all,
    bg_dark,
    btn_close,
    btn_next,
    btn_prev,
    egg_breaker_islan,
    egg_container,
    empty_egg,
    history_container,
    info_container,
    last_winner_container,
    left_pannel_container,
    msgbox_container,
    paging_container,
    point_container,
    star_icon,
    toast_danger,
    toast_error,
    toast_info,
    toast_success,
    btn_send_form_claim,
    blink_cursor_tip,
    bronze_egg_breaker,
    silver_egg_breaker,
    gold_egg_breaker,
    platinum_egg_breaker,
    diamond_egg_breaker,
    glowing_effect_egg_breaker,
    btn_hammer_egg_breaker,
    bronze_crack_egg_breaker,
    silver_crack_egg_breaker,
    gold_crack_egg_breaker,
    platinum_crack_egg_breaker,
    diamond_crack_egg_breaker,
    hammer,
    swing_effect,
    hammered_effect,
    hammer_shadow_effect,
    shadow_effect,
    star,
    trophy_icon,
    icon_exit,
    icon_history,
    icon_info,
    bronze_egg,
    silver_egg,
    gold_egg,
    platinum_egg,
    diamon_egg,
    btn_trade,
    btn_negative,
    btn_positive,
    tray,
    glowing_effect,
    lighting_filter,
    bg_title_prize,
    tray_shadow,
    btn_claim,
    btn_back,

]
export default imagesData