import { Toast, Action } from "../types"

const initState: Toast = {
  isAvaliable: true,
  usedBy: '',
  type: '',
  message: '',
  timeOut: 0
}


// Reducer
export const reducer = (state: Toast=initState, action: Action) => {
  switch (action.type) {
    case 'SET_TOAST':
      return {
        ...state,
        ...action.param as Toast,
        isAvaliable: false,
      }
    case 'UNSET_TOAST':
      return {
          ...state,
          ...action.param  as Toast,
          isAvaliable: true
        }
    default:
      return state
  }
}