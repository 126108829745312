import React, { Suspense, lazy, useLayoutEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import Reducer from './reducer'
import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { createEpicMiddleware } from 'redux-observable'
import { epic } from './epic'
import useLoadFonts from './hook/useLoadFonts'
import useLoadImages from './hook/useLoadImages'
import { Android } from './platform'

const LuckyEgg = lazy(()=> import('./LuckyEgg'))
const epicMiddleware = createEpicMiddleware()
const middleware = applyMiddleware(epicMiddleware)
export const store = createStore(Reducer, composeWithDevTools(middleware))

const App: React.FC = ()=>{

    const useStartUp = () => {
        const fontLoaded = useLoadFonts()
        const imagesLoaded = useLoadImages()
        const [ finish, setFinish ] = useState(false)
        useLayoutEffect(()=>{
            if([fontLoaded.completed, imagesLoaded.completed].every((item)=>item)) return setFinish(true)
        }, [fontLoaded.completed, imagesLoaded.completed])

        return finish
    }

    const startUpCompleted = useStartUp()

    return (<>
        { startUpCompleted && <Suspense fallback={<div>Loading ... </div>}>
                <LuckyEgg store={store} />
            </Suspense>
        }
    </>)
}

epicMiddleware.run(epic)

ReactDOM.render(<App />, document.getElementById('root'))
