import { Action } from "../types"

  interface Texture {
    [index: string]: string
  }
  
  const initState: Texture = {
  }
  
  
  // Reducer
  export const reducer = (state: Texture=initState, action: Action) => {
    const newTexture = action.param as Texture
    switch (action.type) {
      case 'LOAD_TEXTURE':
        return {
          ...state,
          ...newTexture
        }
      default:
        return state
    }
  }